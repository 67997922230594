<template>
  <div>
    <rxNavBar v-if="androidFlg == false" title="完成任务" androidOrIOSFlag="true"></rxNavBar>
    <rxNavBar v-else title="完成任务"></rxNavBar>
      <div id="planInfoPreview"></div>

    <div class="learningAttachmentsTitle">学习附件</div>

    <div class="learningAttachments">
      <van-uploader
                    v-model="fileList"
                    upload-icon="plus"
                    multiple
                    image-fit="contain"
                    :after-read="afterReadTest"
                    @click-preview="clickFile"
      >
      </van-uploader>
    </div>
    <div class="notice"><span>注：建议临时保存后，再上传学习附件，以防所填答案丢失</span></div>

    <div class="sub_btn">
      <van-button  class="saveButton_EnableBlue" size="large" @click="save(0)">临时保存
      </van-button>
      <van-button  :class="checkInput()?'saveButton_Enable':'saveButton_Disable'" :disabled="notClickable" size="large" @click="save(1)">提交
      </van-button>
    </div>
    <!--底部固定栏-->
<!--    <div class="bottom-fixed">-->
<!--&lt;!&ndash;      <div @click="save" class="bottom-fixed-phoneRelation">保存</div>&ndash;&gt;-->
<!--      <div @click="submit" :class="checkInput()?'bottom-fixed-orderLook':'bottom-fixed-enabled'">提交</div>-->
<!--    </div>-->
  </div>
</template>

<script>
import {checkAndroid, checkIOS, responseUtil, getStaffId, globaluserId} from "../../../libs/rongxunUtil";
import {
  Button,
  Divider,
  DropdownItem,
  DropdownMenu,
  List,
  NavBar,
  Picker,
  Popup,
  PullRefresh,
  Search,
  uploader
} from "vant";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {saveFile, trialplanrecord, mainTrialPlanRecordUp} from '../../../getData/getData'

export default {
  name: "probationAssignmentComplete",
  components: {
    [Button.name]:Button,
    [Search.name]:Search,
    [DropdownMenu.name]:DropdownMenu,
    [DropdownItem.name]:DropdownItem,
    [Divider.name]:Divider,
    [NavBar.name]: NavBar,
    [List.name]: List,
    rxNavBar,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [PullRefresh.name]: PullRefresh,
    [uploader .name]:uploader,
  },
  data(){
    return{
      androidFlg:'',
      approvalOpinion:'',
      trialplanrecordList:[],
      fileList:[],
      notClickable:false,
    }
  },
  mounted() {
    this.checkPhoneorMobel()
    this.initProbationPlanDetail()
  },
  methods:{
    checkPhoneorMobel() {
      if(checkAndroid()) {
        this.androidFlg = true
      }
      else if(checkIOS()) {
        this.androidFlg = false
      }
    },
    //获取问答题
    initProbationPlanDetail(){
      let that = this
      let initData = {}
      initData.user_id = getStaffId()
      initData.mainTrialplanRecord_id = this.$route.query.id
      this.trialplanrecordList = []
      trialplanrecord(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.fileList = []  //附件
          for(let i in response.data.data.fileList){
            let map = {}
            map.url = response.data.data.fileList[i].path
            map.id = response.data.data.fileList[i].id
            map.file = new File([], response.data.data.fileList[i].name, {})
            that.fileList.push(map)
          }
          let planDetailList = response.data.data.trialplanrecordList  //替换码

          let content = response.data.data.probationPlan.content  //主文本

          for(let i in planDetailList){
            let charToReplace = planDetailList[i].substitution; // 要被替换的字符
            let charToReplaceStr = (planDetailList[i].substitution).substring(2, planDetailList[i].substitution.length - 2); // 要被替换的绑定键
            let replacementChar = planDetailList[i].answer;  //标准答案
            let ownContent = planDetailList[i].content!=undefined&&planDetailList[i].content!==''?planDetailList[i].content:'';  //作答的填写
            let triId = planDetailList[i].triId  //唯一值

            // 替换后的输入框   placeholder="请输入${planDetailList[i].detailName}"
            let replacementHTML = `<textarea class="divInputBox" placeholder="请输入答案" v-model="${charToReplaceStr}" data-variable="${triId}" @change="valueChange(${charToReplaceStr})">${ownContent}</textarea>`
            content = content.replace(charToReplace,replacementHTML)

            //传给后端的对应输入框的id和值
            let map = {
              triId:triId,
              content:ownContent,
              detailName:planDetailList[i].detailName,
            }
            that.trialplanrecordList.push(map)
          }
          //带输入框的文本显示出来
          let newParagraph = document.createElement("p")  //创建内容元素节点
          newParagraph.insertAdjacentHTML("beforeend",content);  //放HTML  //在元素里最后一个节点后 插入一个html
          let container = document.getElementById("planInfoPreview");
          container.appendChild(newParagraph);

          //赋值class，写样式
          for(let j in document.getElementsByTagName("p")){
            if(typeof document.getElementsByTagName("p")[j]=='object'){
              document.getElementsByTagName("p")[j].className = 'divBox'
            }
          }

          //绑定input事件
          let button = document.getElementsByClassName("divInputBox"); //获取按钮元素
          for(let i = 0;i<=button.length-1;i++){
            button[i].addEventListener("input", (event)=>{
              // console.log("按钮被点击了！=====",button[i].value);
              // console.log("按钮被点击了！=====",button[i].getAttribute('data-variable'));
              let item = that.trialplanrecordList.find(
                  item => item.triId == button[i].getAttribute('data-variable')
              )
              item.content = button[i].value
            });
          }

          //绑定跳转链接事件
          let aherf = document.getElementsByTagName('a')
          for(let i=0;i<=aherf.length-1;i++){
            button[i].addEventListener("click", (event)=>{
              console.log("按钮被点击了！=====",aherf[i]);
              // console.log("按钮被点击了！=====",aherf[i]);
              // let item = that.trialplanrecordList.find(
              //     item => item.triId == button[i].getAttribute('data-variable')
              // )
              // item.content = button[i].value
            });
          }
        })
      })
    },
    //点击查看文件
    clickFile(file){
      console.log(file)
      let extensionThr = file.url.substr(-3)
      let extensionFour = file.url.substr(-4)
      if(extensionThr==='txt' || extensionThr==='xls' || extensionThr==='doc' || extensionFour==='xlsx' || extensionFour==='docx'){
        this.$router.push({
          name:'electronicContract',
          query:{
            type:'ssqian',
            electronicContractUrl: file.url,
            title:file.file.name,
          }
        });
      }else if(extensionThr==='pdf'){
        this.$router.push({
          name:'electronicContract',
          query:{
            type:'fxqian',  //pdf的标识
            electronicContractUrl: file.url,
            title:file.file.name
          }
        });
      }else if(extensionThr==='png'|| extensionThr==='jpg'){
        return;
      }else{
        // responseUtil.alertMsg(this,'暂不支持打开此类文件')
        // return
      }
    },
    //图片多选上传
    afterReadTest(file){
      // this.loadingFlag = true;
      let fileLength = parseInt(file.length) - parseInt(1)
      if(file.length > 1){
        for(let i = 0; i<file.length;i++){
          let fileDetail = file[i];
          this.afterRead(fileDetail,i,fileLength);
        }
      }else{
        this.afterRead(file);
      }
    },
    afterRead(file,i,fileLength) {
      console.log(file.file.name)
      let that = this
      let initData = {
        base64 : file.content
      }
      that.loadingFlag = true;
      saveFile(initData).then(function (response) {
        that.loadingFlag = false;
        responseUtil.dealResponse(that, response, () => {
          file.url = response.data.data.path
          file.name = file.file.name
          if(i == fileLength){
            that.loadingFlag = false
          }
        })
      })
    },


    save(type){
      let that = this
      let initData = {}
      if(type===1 && !this.checkInput('save')){
        return
      }
      if(this.notClickable){
        return;
      }
      initData.trialplanrecordList = this.trialplanrecordList  //triId  content
      initData.fileList = this.fileList
      initData.user_id = getStaffId()
      initData.mainTrialplanRecord_id = this.$route.query.id
      initData.type = type   //(0保存1提交)
      this.notClickable = true
      mainTrialPlanRecordUp(initData).then(function (response) {
        that.notClickable = false
        responseUtil.dealResponse(that, response, () => {
          that.$router.go(-1)
        })
      })
    },
    //验证是否全填
    checkInput(type){
      for(let i in this.trialplanrecordList){
          if(this.trialplanrecordList[i].content ===  ''){
            if(type=='save'){
              responseUtil.alertMsg(this,`【${this.trialplanrecordList[i].detailName}】还未作答！`)
            }
            return false
          }
      }
      return true
    }
  }
}
</script>

<style scoped lang="less">
*{
  margin: 0px;
  padding: 0px;
}
#planInfoPreview{
  box-sizing: border-box;
  margin: 0 0.3rem;
  padding: 0.2rem;
  background-color: white;
  border-radius: 0.5rem;
}
.learningAttachmentsTitle {
  padding: 5px 0px;
  color: rgb(244, 99, 76);
  margin: 25px 0.3rem 0rem 0.4rem;
  font-weight: 700;
  font-size: 12px;
  position: relative;
}
.notice{
  padding: 5px 0px;
  color: rgb(244, 99, 76);
  margin-right: 0.3rem;
  font-weight: 700;
  font-size: 12px;
  position: relative;
  span{
    position: absolute;
    right: 0;
    color: black;
  }
}
.learningAttachments{
  box-sizing: border-box;
  margin: 0 0.3rem;
  padding: 0.4rem;
  background-color: white;
  border-radius: 0.5rem;
}
::v-deep .divInputBox{
  resize: none;
  padding: 10px;
  margin:0px auto 15px;
  height: 120px;
  width: 290px;
  display: block;
  font-size: 14px;
  border-radius: 8px;
  border-style: none;
  background-color: rgba(182, 177, 177, 0.11);
}
::v-deep .divBox{
  font-size: 16px;
}
::v-deep .h3Box{
  margin: 0.5rem 0 0.1rem;
}
::v-deep .h3Box:nth-of-type(1){
  margin-top: 0px;
}
::v-deep img{
  width: 100%;
  height: auto;
}

.sub_btn {
  margin: 0.8rem auto 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/*保存按钮可点击样式*/
.saveButton_EnableBlue{
  background: linear-gradient(to right, #7cc7ff 0px, #3596fd 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
  margin: 0.1rem 0 0.3rem;
}
.saveButton_Enable{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
  margin: 0.1rem 0 0.3rem;
}
.saveButton_Disable{
  display: block;
  background-color: rgba(184, 184, 184, 0.2);
  color: white;
  width: 345px;
  height: 50px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
  margin: 0.1rem;
  /*width: 90%;*/
  /*height: 1.22rem;*/
  /*border-radius: 8px;*/
  /*margin:30px 19px 50px;*/
  /*font-size: 18px;*/
  /*color: white;*/
  /*line-height: 1.22rem;*/
  /*text-align: center;*/
}

.bottom-fixed{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: right;
  background-color: rgba(250, 250, 250, 1);
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.07);
}
.bottom-fixed-orderLook{
  width: 91px;
  height: 40px;
  line-height: 43px;
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
  border-radius: 8px;
  background: linear-gradient(to right,#7cc7ff,#3596fd);
  box-shadow: 0px 1px 1px 0px rgba(0, 115, 202, 0.5);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: PingFangSC-Semibold;
}
.bottom-fixed-enabled{
  width: 91px;
  height: 40px;
  line-height: 43px;
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
  border-radius: 8px;
  background: linear-gradient(to right,#b3dfff,#7cc7ff);
  box-shadow: 0px 1px 1px 0px rgba(0, 115, 202, 0.5);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: PingFangSC-Semibold;
}
.bottom-fixed-phoneRelation{
  width: 91px;
  height: 40px;
  line-height: 43px;
  margin-left: 15px;
  /*margin-right: 15px;*/
  text-align: center;
  border-radius: 8px;
  background: linear-gradient(to right,#ffbe72,#ff6c41);
  box-shadow: 0px 1px 1px 0px rgba(188, 0, 0, 0.2);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: PingFangSC-Semibold;
}
</style>